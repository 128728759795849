import React from "react";
// Full Paper Follow-Up Email
const EigthStepTemplate = ({ values }) => {
  const {
    conference_name,
    client_name,
    conference_date,
    conference_location,
    submission_date_line,
    guideline,
    submission_full_paper,
    contact_support,
    your_name,
    your_title,
    your_contact_information,
    your_website,
  } = values;
  return (
    <div>
      <p>
        {client_name
          ? `${client_name},`
          : ` Dear [Client Name] / Dr. [Client Name],`}
      </p>

      <p>
        We hope this message finds you well. Thank you for registering for{" "}
        <strong>
          {conference_name ? conference_name : "[Conference Name]"}
        </strong>
        , scheduled to take place on{" "}
        <strong>
          {conference_date ? conference_date : "[Conference Dates]"}
        </strong>{" "}
        in{" "}
        <strong>
          {conference_location ? conference_location : "[Conference Venue]"}
        </strong>
        .
      </p>

      <p>
        As the conference approaches, we would like to remind you to submit your
        full paper by{" "}
        <strong>
          {submission_date_line
            ? submission_date_line
            : "[Submission Deadline]"}
        </strong>
        .
      </p>

      <h6>
        <strong>Submission Details:</strong>
      </h6>
      <ul>
        <li>
          <strong>Submission Deadline:</strong>{" "}
          {submission_date_line
            ? submission_date_line
            : "[Submission Deadline]"}
        </li>
        <li>
          <strong>Paper Format:</strong> Please ensure your paper follows the{" "}
          {guideline ? (
            <a href={guideline}>{guideline}</a>
          ) : (
            <a href="[Guidelines Link]">[guidelines provided on our website]</a>
          )}
          .
        </li>
        <li>
          <strong>
            Submission Link:{" "}
            {submission_full_paper
              ? submission_full_paper
              : "[Submit Full Paper]"}
          </strong>
        </li>
      </ul>

      <p>
        Your full paper will undergo a rigorous review process, and selected
        papers will have the opportunity to be published in renowned Scopus and
        Web of Science indexed journals.
      </p>

      <p>
        We are excited to see your research contributions and look forward to
        your active participation at the conference. Should you have any
        questions or need assistance with your submission, please feel free to
        contact us.
      </p>

      <p>
        {contact_support ? (
          <a href={contact_support}>{contact_support}</a>
        ) : (
          <a href="[Contact Support Link]">Contact Support</a>
        )}
      </p>

      <div>Best regards,</div>
      <div>{your_name ? your_name : "[Your Name]"}</div>
      <div>{your_title ? your_title : "[Your Title]"}</div>
      <div>
        <strong>IFERP</strong>
      </div>
      <div>
        {your_contact_information
          ? your_contact_information
          : "[Your Contact Information]"}
      </div>
      <div>
        {your_website ? (
          <a href={your_website}>{your_website}</a>
        ) : (
          "[Your Website]"
        )}
      </div>
    </div>
  );
};

export default EigthStepTemplate;
