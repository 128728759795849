import Button from "components/form/Button";
import Dropdown from "components/form/Dropdown";
import FileUpload from "components/form/FileUpload";
import Location from "components/form/Location";
import MultipleSelect from "components/form/MultipleSelect";
import RadioInput from "components/form/RadioInput";
import TextInput from "components/form/TextInput";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchDetailsOfBulkPayment } from "store/slices";
import {
  getDataFromLocalStorage,
  getFilenameFromUrl,
  objectToFormData,
} from "utils/helpers";
import * as Yup from "yup";

const SingleForm = ({ type, paperData, handleSuccess }) => {
  const params = useParams();
  const dispatch = useDispatch();
  const [btnLoading, setBtnLoading] = useState(false);
  const [isCoAuthorBlock, setIsCoAuthorBlock] = useState(false);
  // const [bulkPaymentDetails, setBulkPaymentDetails] = useState({});
  const initialValues = {
    abstract_id: params?.paperId,
    payment_by: getDataFromLocalStorage("id"),
    presentation_type: "",
    payment_category: "",
    payment_mode: "",
    payment_proof: "",
    payment_proof_name: "",
    payment_remarks: "",
    country: "",
    state: "",
    city: "",
    address: "",
    zip_code: "",
    payment_type: type,
    amount: "",
    reference_no: "",
    bulk_paper_title: "",
    bulk_paper_id: "",
  };
  const validationSchema = Yup.object().shape({});
  const handlePayment = async (value) => {
    setBtnLoading(true);
    console.log("values", value);
    setBtnLoading(false);
  };

  const getBulkPaymentDetails = async () => {
    const response = await dispatch(
      fetchDetailsOfBulkPayment(
        objectToFormData({ user_id: paperData?.user_id })
      )
    );
    if (response?.status === 200) {
      // setBulkPaymentDetails();
    }
  };

  useEffect(() => {
    if (type === "1") {
      getBulkPaymentDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handlePayment}
    >
      {(props) => {
        const { values, handleChange, setFieldValue, handleSubmit } = props;
        const {
          presentation_type,
          payment_category,
          payment_mode,
          payment_proof_name,
          payment_remarks,
          country,
          state,
          city,
          address,
          zip_code,
          amount,
          reference_no,
          bulk_paper_title,
          bulk_paper_id,
        } = values;

        return (
          <form>
            <div className="row">
              {type === "0" && (
                <>
                  <div className="col-lg-12 cmb-24">
                    <div className="cmb-5 text-14-400-18 color-1717">
                      Paper Name :
                    </div>
                    <div className="text-14-300-24 color-black-olive">
                      {paperData?.paperTitle}
                    </div>
                  </div>

                  <div className="col-lg-4 cmb-24">
                    <div className="cmb-5 text-14-400-18 color-1717">
                      Paper ID:
                    </div>
                    <div className="text-14-300-24 color-black-olive">
                      {paperData?.paperId}
                    </div>
                  </div>

                  <div className="col-lg-8 cmb-24"></div>
                </>
              )}

              {type === "1" && (
                <>
                  <div className="col-lg-12 cmb-24">
                    <MultipleSelect
                      label={"Paper Title :"}
                      id="bulk_paper_title"
                      placeholder="select"
                      options={[{ id: "paper 1" }, { id: "paper 2" }]}
                      value={bulk_paper_title}
                      optionValue={"id"}
                      optionKey={"id"}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="col-lg-12 cmb-24">
                    <MultipleSelect
                      label={"Paper ID :"}
                      id="bulk_paper_id"
                      placeholder="select"
                      value={bulk_paper_id}
                      options={[{ id: "12345" }, { id: "67890" }]}
                      optionKey={"id"}
                      optionValue={"id"}
                      onChange={handleChange}
                    />
                  </div>
                </>
              )}

              <div className="col-lg-4 cmb-24">
                <div className="cmb-5 text-14-400-18 color-1717">
                  Presentation Type :
                </div>
                <div className="text-14-300-24 color-black-olive">
                  {paperData?.presentation_type}
                </div>
              </div>

              <div className="col-lg-4 cmb-24">
                <div className="cmb-5 text-14-400-18 color-1717">
                  Author Name :
                </div>
                <div className="text-14-300-24 color-black-olive">
                  {paperData?.authorName}
                </div>
              </div>
              <div className="col-lg-4 cmb-24">
                <div className="cmb-5 text-14-400-18 color-1717">
                  Presentation :
                </div>
                <div className="d-flex align-items-center gap-3">
                  <div className="d-flex align-items-center">
                    <RadioInput
                      name="presentation_type"
                      id="presentation_type"
                      onChange={() => {
                        setFieldValue("presentation_type", "Physical");
                      }}
                      checked={presentation_type === "Physical"}
                    />

                    <div className="text-16-400 color-raisin-black ms-3">
                      Physical
                    </div>
                  </div>

                  <div className="d-flex align-items-center">
                    <RadioInput
                      name="presentation_type"
                      id="presentation_type"
                      onChange={() => {
                        setFieldValue("presentation_type", "Virtual");
                      }}
                      checked={presentation_type === "Virtual"}
                    />

                    <div className="text-16-400 color-raisin-black ms-3">
                      Virtual
                    </div>
                  </div>
                  <div className="d-flex align-items-center">
                    <RadioInput
                      name="presentation_type"
                      id="presentation_type"
                      onChange={() => {
                        setFieldValue("presentation_type", "Hybrid");
                      }}
                      checked={presentation_type === "Hybrid"}
                    />

                    <div className="text-16-400 color-raisin-black ms-3">
                      Hybrid
                    </div>
                  </div>
                </div>
              </div>

              <div className={`col-lg-6 cmb-24`}>
                <div className={`${isCoAuthorBlock ? "border" : ""}   `}>
                  <div
                    className="d-flex align-items-center justify-content-between text-14-500-18 color-new-car bg-f4ff pointer cmb-22 cps-24 cpt-12 cpb-12 cpe-24"
                    onClick={() => {
                      setIsCoAuthorBlock(!isCoAuthorBlock);
                    }}
                  >
                    Co-Author Name
                    {isCoAuthorBlock ? (
                      <i className="bi bi-chevron-up color-new-car" />
                    ) : (
                      <i className="bi bi-chevron-down color-new-car" />
                    )}
                  </div>

                  {isCoAuthorBlock &&
                    paperData?.co_author_details?.map((elem, index) => {
                      return (
                        <div className="cps-24 cpe-24 row" key={index}>
                          <div className="col-lg-4 cmb-24">
                            <div className="cmb-5 text-14-400-18 color-1717">
                              Co Author Name :
                            </div>
                            <div className="text-14-300-24 color-black-olive">
                              {elem?.name}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
              <div className="col-lg-6"></div>
              <div className="col-lg-4 cmb-24">
                <TextInput
                  label="Reference No :"
                  id="reference_no"
                  placeholder="Enter reference no"
                  value={reference_no}
                  onChange={handleChange}
                />
              </div>
              <div className="col-lg-8"></div>

              <div className="col-lg-4 cmb-24">
                <TextInput
                  label="Total Amount :"
                  id="amount"
                  placeholder="Enter amount"
                  value={amount}
                  onChange={handleChange}
                />
              </div>
              <div className="col-lg-4 cmb-24">
                <Dropdown
                  label={"Payment Category :"}
                  id="payment_category"
                  placeholder="select"
                  options={[
                    { name: "Journal Publication", value: "1" },
                    { name: "Submitted Abstract", value: "2" },
                  ]}
                  optionValue={"name"}
                  optionKey={"value"}
                  value={payment_category}
                  onChange={handleChange}
                />
              </div>
              <div className="col-lg-4 cmb-24">
                <Dropdown
                  label={"Payment Mode :"}
                  id="payment_mode"
                  placeholder="select"
                  options={[{ name: "UPI" }]}
                  value={payment_mode}
                  optionValue={"name"}
                  optionKey={"name"}
                  onChange={handleChange}
                />
              </div>

              <div className="col-lg-4 cmb-24">
                <FileUpload
                  label={"Payment Proof :"}
                  id="payment_proof"
                  fileText={getFilenameFromUrl(
                    payment_proof_name || "Payment Proof"
                  )}
                  onChange={(e) => {
                    console.log("e", e);
                    const fileName = e.target.fileName;
                    const file = e.target.value;
                    setFieldValue("payment_proof_name", fileName);
                    setFieldValue("payment_proof", file);
                  }}
                />
              </div>

              <div className="col-lg-8 cmb-24">
                <TextInput
                  label="Remarks :"
                  id="payment_remarks"
                  value={payment_remarks}
                  placeholder="Enter remarks"
                  onChange={handleChange}
                />
              </div>

              <div className="text-16-400-20 color-1717 cmb-24">
                Billing Address :
              </div>
              <div className="col-lg-4 cmb-24">
                <Location
                  type="country"
                  data={{
                    label: "Country :",
                    id: "country",
                    placeholder: "select",
                    value: country,
                    onChange: handleChange,
                  }}
                />
              </div>
              <div className="col-lg-4 cmb-24">
                <Location
                  type="state"
                  data={{
                    label: "State :",
                    id: "state",
                    placeholder: "select",
                    value: state,
                    countryId: country,
                    disabled: !country,
                    onChange: handleChange,
                  }}
                />
              </div>
              <div className="col-lg-4 cmb-24">
                <TextInput
                  label="City :"
                  id="city"
                  value={city}
                  placeholder="Enter city"
                  onChange={handleChange}
                />
              </div>

              <div className="col-lg-8 cmb-24">
                <TextInput
                  label="Address :"
                  id="address"
                  value={address}
                  placeholder="Enter address"
                  onChange={handleChange}
                />
              </div>

              <div className="col-lg-4 cmb-24">
                <TextInput
                  label="Zip Code :"
                  id="zip_code"
                  value={zip_code}
                  placeholder="Enter Zip code"
                  onChange={handleChange}
                />
              </div>

              <div className="d-flex">
                <Button
                  text={"Submit"}
                  btnStyle={"primary-dark"}
                  className={"h-35 cps-20 cpe-20"}
                  btnLoading={btnLoading}
                  onClick={handleSubmit}
                />
              </div>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default SingleForm;
