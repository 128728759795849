import React from "react";
// Coupon or Voucher follow ups/early bird deadline
const SecondStepTemplate = ({ values }) => {
  const {
    coupon_code,
    conference_name,
    client_name,
    conference_date,
    conference_location,
    discount_amount,
    expiration_date,
    register_now,
    support_email,
    your_name,
    your_title,
    your_contact_information,
    your_website,
  } = values;
  return (
    <div>
      <p>
        {client_name
          ? `${client_name},`
          : ` Dear [Client Name] / Dr. [Client Name],`}
      </p>
      <p>Greetings from IFERP!</p>
      <p>
        We are excited to offer you an exclusive opportunity to register for{" "}
        <strong>
          {conference_name ? conference_name : "[Conference Name]"}
        </strong>{" "}
        with a special discount! The conference will take place from{" "}
        <strong>
          {conference_date ? conference_date : "[Conference Dates]"}
        </strong>{" "}
        at{" "}
        <strong>
          {conference_location ? conference_location : "[Conference Venue]"}
        </strong>
        .
      </p>

      <h6>
        <strong>Exclusive Discount:</strong>
      </h6>
      <p>
        As a valued member of our community, you can use the coupon code{" "}
        <strong>{coupon_code ? coupon_code : "[Coupon Code]"}</strong> to enjoy
        a{" "}
        <strong>
          {discount_amount ? discount_amount : "[Discount Amount]"}%
        </strong>{" "}
        discount on your registration fee. Don’t miss this chance—apply the code
        during registration and save before the offer expires on{" "}
        <strong>
          {expiration_date ? expiration_date : "[Expiration Date]"}
        </strong>
        !
      </p>

      <h6>
        <strong>
          Why Attend {conference_name ? conference_name : "[Conference Name]"}?
        </strong>
      </h6>
      <ul>
        <li>
          <strong>Keynote Speakers:</strong> Hear from experts across 7+
          countries in the field.
        </li>
        <li>
          <strong>Networking Opportunities:</strong> Connect with professionals
          from over 12+ countries and expand your research collaborations.
        </li>
        <li>
          <strong>Workshops and Panels:</strong> Participate in 2+ interactive
          workshops to gain practical insights into research, article writing,
          and career development.
        </li>
        <li>
          <strong>Publication Opportunities:</strong> Selected papers will be
          submitted to Scopus and Web of Science (WoS) for evaluation and
          indexing.
        </li>
        <li>
          <strong>SDG Alignment:</strong> Sessions aligned with the United
          Nations’ Sustainable Development Goals (SDGs), promoting global
          progress through research.
        </li>
        <li>
          <strong>Certifications:</strong> Earn globally recognized
          certifications for your contributions and participation.
        </li>
      </ul>

      <h6>
        <strong>Conference Statistics:</strong>
      </h6>
      <ul>
        <li>
          5+ Technical Sessions covering groundbreaking research and
          innovations.
        </li>
        <li>
          2 Best Paper Awards and 2 Best Paper Presentation Awards to recognize
          excellence.
        </li>
        <li>3 Research Excellence Awards for outstanding contributions.</li>
        <li>
          2+ Pre-Conference Workshops offering insights into research
          methodologies and article writing.
        </li>
        <li>
          Panel Discussions with global experts to foster impactful discussions.
        </li>
        <li>
          1080 minutes of content from leading academics and professionals.
        </li>
      </ul>

      <h6>
        <strong>Scholarships and Awards:</strong>
      </h6>
      <p>
        We offer scholarships to support participation and inclusivity, along
        with opportunities to earn awards that will enhance your academic
        profile.
      </p>

      <h6>
        <strong>Pre-Conference Workshops:</strong>
      </h6>
      <p>
        Additionally, attend our pre-conference workshops to sharpen your skills
        in article writing, enhance your research methodologies, and network
        with peers—building connections before the main event.
      </p>

      <h6>
        <strong>Your Coupon Details:</strong>
      </h6>
      <ul>
        <li>
          <strong>Coupon Code:</strong>{" "}
          {coupon_code ? coupon_code : "[Coupon Code]"}
        </li>
        <li>
          <strong>Discount Amount:</strong>{" "}
          {discount_amount ? discount_amount : "[Discount Amount]"}
        </li>
        <li>
          <strong>Expiration Date:</strong>{" "}
          {expiration_date ? expiration_date : "[Expiration Date]"}
        </li>
      </ul>

      <h6>
        <strong>Next Steps:</strong>
      </h6>
      <p>
        Register today and take advantage of this special offer by using the
        coupon code during checkout!{" "}
        <strong>[CTA: {register_now ? register_now : "Register Now"}]</strong>
      </p>

      <p>
        If you have any questions or need further assistance, feel free to
        contact us at{" "}
        {support_email ? (
          <a href={support_email}>{support_email} </a>
        ) : (
          "[SupportEmail]"
        )}
        .
      </p>

      <p>
        We look forward to welcoming you to{" "}
        <strong>
          {conference_name ? conference_name : "[Conference Name]"}
        </strong>
        !
      </p>

      <div>Best regards,</div>
      <div>{your_name ? your_name : "[Your Name]"}</div>
      <div>{your_title ? your_title : "[Your Title]"}</div>
      <div>
        <strong>IFERP</strong>
      </div>
      <div>
        {your_contact_information
          ? your_contact_information
          : "[Your Contact Information]"}
      </div>
      <div>
        {your_website ? (
          <a href={your_website}>{your_website}</a>
        ) : (
          "[Your Website]"
        )}
      </div>
    </div>
  );
};

export default SecondStepTemplate;
