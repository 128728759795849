import Button from "components/form/Button";
import Modal from "components/Layout/Modal";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { deleteReviewer, throwError, throwSuccess } from "store/slices";
import { objectToFormData } from "utils/helpers";

const ReviewerRemovePopup = ({ onHide, elem, abstractId, handleSuccess }) => {
  const dispatch = useDispatch();
  const [btnLoading, setBtnLoading] = useState(false);

  const handleRemove = async () => {
    setBtnLoading(true);
    const response = await dispatch(
      deleteReviewer(
        objectToFormData({ id: elem?.id, abstract_id: abstractId })
      )
    );
    if (response?.status === 200) {
      dispatch(throwSuccess(response?.message));
      handleSuccess();
      onHide();
    } else {
      dispatch(throwError({ message: response?.messsage }));
    }

    setBtnLoading(false);
  };
  return (
    <Modal
      onHide={onHide}
      titleBesideClose={"Are you sure want to remove reviewer?"}
      isTitleVerticalCenter
      titleBesideCloseClassName={"text-16-300-20 color-4141"}
    >
      <div className="d-flex align-items-center gap-3 cmt-15 cps-20 cpe-20 cpb-10">
        <Button
          text="Yes, Remove"
          btnStyle="primary-dark"
          className="h-35 cps-15 cpe-15"
          onClick={handleRemove}
          btnLoading={btnLoading}
        />
        <Button
          text="Cancel"
          btnStyle="primary-outline"
          className="h-35 cps-15 cpe-15"
          onClick={onHide}
        />
      </div>
    </Modal>
  );
};

export default ReviewerRemovePopup;
