import React from "react";
// Hall Ticket, Zoom link and Confirmation
const SeventhStepTemplate = ({ values }) => {
  const {
    conference_name,
    client_name,
    venue_name,
    street_address,
    city,
    state,
    postal_code,
    country,
    map_link,
    zoom_link,
    download_zoom,
    registration_venue_name,
    registration_street_address,
    registration_date_time,
    hall_ticket_link,
    support_email,
    support_phone,
    your_name,
    your_title,
    your_contact_information,
    your_website,
  } = values;
  return (
    <div>
      <p>
        {client_name
          ? `${client_name},`
          : ` Dear [Client Name] / Dr. [Client Name],`}
      </p>

      <p>
        We are excited to share the final agenda for{" "}
        <strong>
          {conference_name ? conference_name : "[Conference Name]"}
        </strong>{" "}
        and provide you with all the necessary details to ensure a seamless and
        enjoyable experience.
      </p>

      <h6>
        <strong>Final Agenda:</strong>
      </h6>
      <p>
        Please find the detailed final agenda attached to this email. This
        comprehensive schedule includes all sessions, workshops, and networking
        events, allowing ample time for presentations, Q&A, and participant
        interaction.
      </p>

      <h6>
        <strong>Venue Information:</strong>
      </h6>
      <p>
        <strong>Venue Address:</strong>{" "}
        {venue_name ? venue_name : "[Venue Name]"},{" "}
        {street_address ? street_address : "[Street Address]"},{" "}
        {city ? city : "[City]"},{state ? state : "[State/Province]"},{" "}
        {postal_code ? postal_code : "[Postal Code]"},{" "}
        {country ? country : "[Country]"}
      </p>
      <p>
        <strong>Google Maps Link:</strong>{" "}
        {map_link ? (
          <a href={map_link}>{map_link}</a>
        ) : (
          <a href="[Google Maps Link]">[Google Maps Link]</a>
        )}
      </p>

      <h6>
        <strong>Zoom Link for Virtual Participation:</strong>
      </h6>
      <p>
        If you’re participating virtually, you can join the conference using the
        link below:
      </p>
      <p>
        <strong>{zoom_link ? zoom_link : "[Access Zoom Link]"}</strong>
      </p>

      <h6>
        <strong>Zoom Background:</strong>
      </h6>
      <p>
        For a uniform virtual experience, you can download the official Zoom
        background here:{""}
        <strong>
          {download_zoom ? (
            <a href={download_zoom}>{download_zoom}</a>
          ) : (
            "[Download Zoom Background]"
          )}
        </strong>
      </p>

      <h6>
        <strong>On-Site Registration Details:</strong>
      </h6>
      <p>
        <strong>Location:</strong> Registration Desk,{" "}
        {registration_venue_name ? registration_venue_name : "[Venue Name]"},{" "}
        {registration_street_address
          ? registration_street_address
          : "[Street Address]"}
      </p>
      <p>
        <strong>Date and Time:</strong>{" "}
        {registration_date_time
          ? registration_date_time
          : "[Date and Time Range for Registration]"}
      </p>

      <h6>
        <strong>What to Bring:</strong>
      </h6>
      <ul>
        <li>A valid ID for verification</li>
        <li>A copy of your registration confirmation</li>
        <li>Any materials or devices needed for your presentation</li>
      </ul>

      <h6>
        <strong>Access Your Hall Ticket/Zoom Link:</strong>
      </h6>
      <p>
        {hall_ticket_link ? (
          <a href={hall_ticket_link}>{hall_ticket_link}</a>
        ) : (
          <a href="[Access Hall Ticket/Zoom Link]">
            Access Hall Ticket/Zoom Link
          </a>
        )}
      </p>

      <p>
        If you have any questions or require further assistance, please feel
        free to contact our support team at{" "}
        {support_email ? support_email : "[Support Email]"} or{" "}
        {support_phone ? support_phone : "[Support Phone Number]"}.
      </p>

      <p>We look forward to your participation and a successful conference!</p>

      <div>Best regards,</div>
      <div>{your_name ? your_name : "[Your Name]"}</div>
      <div>{your_title ? your_title : "[Your Title]"}</div>
      <div>
        <strong>IFERP</strong>
      </div>
      <div>
        {your_contact_information
          ? your_contact_information
          : "[Your Contact Information]"}
      </div>
      <div>
        {your_website ? (
          <a href={your_website}>{your_website}</a>
        ) : (
          "[Your Website]"
        )}
      </div>
    </div>
  );
};

export default SeventhStepTemplate;
