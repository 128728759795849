import React, { useEffect, useState } from "react";
import Card from "components/Layout/Card";
import { useNavigate, useParams } from "react-router-dom";
import { icons } from "utils/constants";
import BasicInfo from "./BasicInfo";
import { useDispatch } from "react-redux";
import { fetchSubmittedAbstractsDetails } from "store/slices";
import { getFilenameFromUrl } from "utils/helpers";
import Loader from "components/Layout/Loader";
import UpdateAbstractByResourceForm from "../UpdateAbstractByResourceForm";
import RegistrationState from "./RegistrationState";
import AbstractState from "./AbstractState";
import ReviewState from "./ReviewState";
import PlagiarismState from "./PlagiarismState";
import AcceptanceState from "./AcceptanceState";
import "./NewPaperDetails.scss";

const NewPaperDetails = () => {
  const isEvent =
    window.location.pathname.includes("event-management") ||
    window.location.pathname.includes("my-events") ||
    window.location.pathname.includes("my-profile");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const [isLoading, setLoading] = useState(true);
  const [isPaperDetail, setIsPaperDetail] = useState(true);
  const [paperData, setPaperData] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [btnLoader, setBtnLoader] = useState("");

  const [data, setData] = useState({
    paper_submitted_status: "",
    plagiarism_comment: "",
    plagiarism_document: "",
    review_comment: "",
    acceptance_status: "",
    registration_acceptance_letter: "",
    acceptance_date: "",
    paper_submitted_date: "",
    plagiarism_date: "",
    review_date: "",
    registration_date: "",
    plagiarism_document_file_name: "",
    acceptance_letter_file_name: "",
    republish_date: "",
    is_republish_by_admin: "0",
    is_remodify: "2",
    reviewing_file: "",
    reviewing_file_file_name: "",
  });
  const getPaperDetails = async () => {
    let response = null;
    if (isEvent) {
      response = await dispatch(fetchSubmittedAbstractsDetails(params.paperId));
    }
    const resData = response?.data || null;

    const statusData = resData?.submittedPapersStatus || {};
    setPaperData(resData);
    setData({
      ...data,
      ...statusData,
      plagiarism_document_file_name: getFilenameFromUrl(
        statusData?.plagiarism_document
      ),
      acceptance_letter_file_name: getFilenameFromUrl(
        statusData?.registration_acceptance_letter
      ),
    });
    setLoading(false);
    setBtnLoader("");
  };

  useEffect(() => {
    getPaperDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { paper_submitted_status, plagiarism_status: plagiarismStatus } =
    data || {};

  const status = +paper_submitted_status || 1;
  const plagiarismAccepted = +plagiarismStatus === 1;

  return (
    <div className="new-paper-details-container">
      {isEdit && (
        <UpdateAbstractByResourceForm
          onHide={() => {
            setIsEdit(false);
          }}
          elem={isEdit}
          handleSuccess={() => {
            getPaperDetails();
          }}
        />
      )}
      {!isLoading && (
        <Card className="cmb-18">
          <div className="bg-f4ff color-new-car text-14-500-17 cps-44 cpt-12 cpe-28 cpb-12 d-flex align-items-center gap-2">
            <div
              className="d-flex"
              onClick={() => {
                navigate(-1);
              }}
            >
              <img
                src={icons.leftArrow}
                alt="back"
                className="h-21 me-3 pointer"
              />
            </div>
            <div
              className="d-flex align-items-center justify-content-between pointer flex-grow-1"
              onClick={() => {
                setIsPaperDetail(!isPaperDetail);
              }}
            >
              <div>{paperData?.paperId}</div>
              <i
                className={`${
                  isPaperDetail ? "bi bi-chevron-up" : "bi bi-chevron-down"
                }`}
                style={{ fontSize: "15px" }}
              />
            </div>
          </div>

          {isPaperDetail && <BasicInfo paperData={paperData} />}
        </Card>
      )}

      {isLoading ? (
        <Card className="cps-44 cpt-25 cpe-44 cpb-25">
          <div className="pt-5 pb-5">
            <Loader size="md" />
          </div>
        </Card>
      ) : (
        <Card className="cps-44 cpt-25 cpe-44 cpb-25 paper-tracking-section">
          {/* Abstract */}
          <AbstractState
            data={data}
            paperData={paperData}
            isEvent={isEvent}
            handleSuccess={() => {
              getPaperDetails();
            }}
            setIsEdit={setIsEdit}
          />
          {/* Plagiarism */}
          <PlagiarismState
            btnLoader={btnLoader}
            setBtnLoader={setBtnLoader}
            data={data}
            paperData={paperData}
            handleSuccess={() => {
              getPaperDetails();
            }}
          />
          {/* Review */}
          <ReviewState
            status={status}
            plagiarismAccepted={plagiarismAccepted}
            data={data}
            paperData={paperData}
            handleSuccess={() => {
              getPaperDetails();
            }}
          />
          {/* Acceptance */}
          <AcceptanceState
            status={status}
            paperData={paperData}
            data={data}
            handleSuccess={() => {
              getPaperDetails();
            }}
          />

          {/* Registration */}
          <RegistrationState
            status={status}
            paperData={paperData}
            data={data}
            handleSuccess={() => {
              getPaperDetails();
            }}
          />
        </Card>
      )}
    </div>
  );
};

export default NewPaperDetails;
