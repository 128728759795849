import DatePicker from "components/form/DatePicker";
import TextInput from "components/form/TextInput";
import React from "react";

// "Full Paper Follow-Up Email"
const EigthStepOfFollowUps = ({ props }) => {
  const { handleChange, values } = props;
  const {
    conference_name,
    client_name,
    conference_date,
    conference_location,
    submission_date_line,
    guideline,
    submission_full_paper,
    contact_support,
    your_name,
    your_title,
    your_contact_information,
    your_website,
  } = values;
  return (
    <>
      <div className="d-flex align-items-center flex-wrap cmb-19">
        <div className="color-black-olive text-14-400-17 col-lg-6">
          Conference Name
        </div>
        <div className="col-lg-6">
          <TextInput
            id="conference_name"
            onChange={handleChange}
            value={conference_name}
          />
        </div>
      </div>
      <div className="d-flex align-items-center flex-wrap cmb-19">
        <div className="color-black-olive text-14-400-17 col-lg-6">
          Client Name
        </div>
        <div className="col-lg-6">
          <TextInput
            id="client_name"
            onChange={handleChange}
            value={client_name}
          />
        </div>
      </div>

      <div className="d-flex align-items-center flex-wrap cmb-19">
        <div className="color-black-olive text-14-400-17 col-lg-6">Date</div>
        <div className="col-lg-6">
          <DatePicker
            id="conference_date"
            onChange={handleChange}
            value={conference_date}
          />
        </div>
      </div>
      <div className="d-flex align-items-center flex-wrap cmb-19">
        <div className="color-black-olive text-14-400-17 col-lg-6">Venue</div>
        <div className="col-lg-6">
          <TextInput
            id="conference_location"
            onChange={handleChange}
            value={conference_location}
          />
        </div>
      </div>

      <div className="d-flex align-items-center flex-wrap cmb-19">
        <div className="color-black-olive text-14-400-17 col-lg-6">
          Guidelines
        </div>
        <div className="col-lg-6">
          <TextInput id="guideline" onChange={handleChange} value={guideline} />
        </div>
      </div>
      <div className="d-flex align-items-center flex-wrap cmb-19">
        <div className="color-black-olive text-14-400-17 col-lg-6">
          Submission Deadline
        </div>
        <div className="col-lg-6">
          <TextInput
            id="submission_date_line"
            onChange={handleChange}
            value={submission_date_line}
          />
        </div>
      </div>

      <div className="d-flex align-items-center flex-wrap cmb-19">
        <div className="color-black-olive text-14-400-17 col-lg-6">
          Submit Full Paper
        </div>
        <div className="col-lg-6">
          <TextInput
            id="submission_full_paper"
            onChange={handleChange}
            value={submission_full_paper}
          />
        </div>
      </div>
      <div className="d-flex align-items-center flex-wrap cmb-19">
        <div className="color-black-olive text-14-400-17 col-lg-6">
          Contact Support
        </div>
        <div className="col-lg-6">
          <TextInput
            id="contact_support"
            onChange={handleChange}
            value={contact_support}
          />
        </div>
      </div>
      <div className="d-flex align-items-center flex-wrap cmb-20">
        <div className="color-black-olive text-14-400-17 col-lg-6">
          Your Name
        </div>
        <div className="col-lg-6">
          <TextInput id="your_name" onChange={handleChange} value={your_name} />
        </div>
      </div>
      <div className="d-flex align-items-center flex-wrap cmb-20">
        <div className="color-black-olive text-14-400-17 col-lg-6">
          Your Title
        </div>
        <div className="col-lg-6">
          <TextInput
            id="your_title"
            onChange={handleChange}
            value={your_title}
          />
        </div>
      </div>

      <div className="d-flex align-items-center flex-wrap cmb-20">
        <div className="color-black-olive text-14-400-17 col-lg-6">
          Your Contact Information
        </div>
        <div className="col-lg-6">
          <TextInput
            id="your_contact_information"
            onChange={handleChange}
            value={your_contact_information}
          />
        </div>
      </div>

      <div className="d-flex align-items-center flex-wrap cmb-20">
        <div className="color-black-olive text-14-400-17 col-lg-6">
          Your Website
        </div>
        <div className="col-lg-6">
          <TextInput
            id="your_website"
            onChange={handleChange}
            value={your_website}
          />
        </div>
      </div>
    </>
  );
};

export default EigthStepOfFollowUps;
