import Card from "components/Layout/Card";
import ExportButton from "components/Layout/ExportButton";
import FilterDropdown from "components/Layout/FilterDropdown";
import Table from "components/Layout/Table";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  exportSubmittedAbstracts,
  fetchConferencesList,
  fetchDetailedViewData,
} from "store/slices";
import {
  getOtherStatus,
  getStatus,
  objectToFormData,
  titleCaseString,
} from "utils/helpers";
import ActionFilter from "../ActionFilter/ActionFilter";
import { limit } from "utils/constants";
import { useNavigate, useParams } from "react-router-dom";
import CommentAddView from "../CommentAddView";

const leadColor = (values = "") => {
  if (values) {
    if (values === "Warm") {
      return "color-a700";
    } else if (values === "Hot") {
      return "color-4646";
    } else if (values === "Cold") {
      return "color-7cea";
    } else {
      return "color-black";
    }
  }
};

const DetailedView = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { membershipList, countryList, conferencesList } = useSelector(
    (state) => ({
      membershipList: state.global.membershipList,
      countryList: state.global.countryList,
      conferencesList: state.global.conferencesList,
    })
  );
  const { memberType, moduleType } = params;

  const [abstractId, setAbstractId] = useState(null);
  const [tableList, setTableList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchPayload, setSearchPayload] = useState({
    paper_id: "",
    paper_title: "",
    member_id: "",
    member_type: "",
    author_name: "",
    author_email: "",
    conference: "",
    session: "",
    university: "",
    country: "",
    status: "",
    statusType: "",
    statuss: "",
    lead: "",
    remarks: "",
    abstract_id_or_title: "",
  });
  const [filterData, setFilterData] = useState({
    total: 0,
    offset: 0,
    limit: limit,
    membership_plan_id: "",
  });

  const getDetailedViewData = async (obj) => {
    let formData = new FormData();
    if (obj?.statusValue) {
      obj?.statusValue?.forEach((value) => {
        formData.append(
          `status[${obj?.statusType?.toLowerCase()}][${value}]`,
          value
        );
      });
    }
    const keysToOmit = ["statusType", "statuss", "statusValue", "status"];
    for (const key in obj) {
      if (!keysToOmit.includes(key)) {
        formData.append(key, obj[key]);
      }
    }
    const response = await dispatch(fetchDetailedViewData(formData));
    setTableList(response?.data?.abstracts || []);
    setFilterData({
      ...obj,
      total: response?.data?.result_count || 0,
    });
    setIsLoading(false);
  };

  const handelChangeSearch = (searchData) => {
    setIsLoading(true);
    let newData = filterData;
    setSearchPayload(searchData);
    newData = { ...newData, ...searchData, offset: 0 };
    setFilterData(newData);
    getDetailedViewData(newData);
  };

  const handelChangePagination = (offset) => {
    setIsLoading(true);
    let newData = { ...filterData, ...searchPayload };
    newData = { ...newData, offset: offset };
    setFilterData(newData);
    getDetailedViewData(newData);
  };

  const getConferenceList = async () => {
    await dispatch(fetchConferencesList());
  };

  const handelChangeFilter = (val) => {
    setIsLoading(true);
    let newData = { ...filterData, ...searchPayload };
    newData = { ...newData, membership_plan_id: val, offset: 0 };
    setFilterData(newData);
    getDetailedViewData(newData);
  };

  const handleToggle = (id) => {
    if (abstractId === id) {
      setAbstractId(null);
    } else {
      setAbstractId(id);
    }
  };

  useEffect(() => {
    getDetailedViewData({ ...filterData, ...searchPayload });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchPayload]);

  useEffect(() => {
    getConferenceList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRedirect = (paperId) => {
    let newModuleType = "";

    if (!moduleType && window.location.pathname.includes("my-profile")) {
      newModuleType = "my-profile";
    } else {
      newModuleType = moduleType;
    }
    navigate(`/${memberType}/${newModuleType}/submitted-papers/${paperId}`);
  };

  let header = [
    {
      isSearch: false,
      searchInputName: "name",
      title: "S.No",
    },
    {
      isSearch: true,
      searchInputName: "paper_id",
      title: "Paper ID",
    },
    {
      isSearch: true,
      searchInputName: "paper_title",
      title: "Paper Title",
    },

    {
      isSearch: true,
      searchInputName: "member_id",
      title: <div>Member ID</div>,
    },
    {
      isSearch: true,
      isSearchDropdown: true,
      dropdownOptions: { options: membershipList, key: "name", value: "name" },
      searchInputName: "member_type",
      title: <div className="text-nowrap">Member Type</div>,
    },
    {
      isSearch: true,
      searchInputName: "author_name",
      title: "Author",
    },
    {
      isSearch: true,
      searchInputName: "author_email",
      title: <div className="text-nowrap">Author Email</div>,
    },
    {
      isSearch: true,
      isSearchDropdown: true,
      dropdownOptions: {
        options: conferencesList || [],
        key: "event_name",
        value: "event_name",
      },
      searchInputName: "conference",
      title: <div className="text-nowrap">Conference Name</div>,
    },
    // {
    //   isSearch: true,
    //   isSearchDropdown: true,
    //   dropdownOptions: { options: [], key: "name", value: "name" },
    //   searchInputName: "session",
    //   title: "Session",
    // },
    {
      isSearch: true,
      searchInputName: "university",
      title: "University",
    },
    {
      isSearch: true,
      isSearchDropdown: true,
      dropdownOptions: {
        options: countryList || [],
        key: "id",
        value: "country",
      },
      searchInputName: "country",
      title: "Country",
    },
    {
      isSearch: true,
      isPaperStatusFilter: true,
      handlePaperStatusChange: (e) => {
        setSearchPayload((prev) => {
          return {
            ...prev,
            statusType: e.category,
            statuss: e.subCategory,
            statusValue: e.subCategoryValue,
          };
        });
      },
      searchInputName: "status",
      title: "Status",
    },
    {
      isSearch: true,
      isSearchDropdown: true,
      dropdownOptions: {
        options: [
          // { name: "Pending" },
          { name: "Cold" },
          { name: "Hot" },
          { name: "Warm" },
        ],
        key: "name",
        value: "name",
      },
      searchInputName: "lead",
      title: "Lead",
    },
    {
      isSearch: true,
      searchInputName: "remarks",
      title: "Remarks",
    },
    {
      isSearch: false,
      searchLable: "",
      title: "Action",
    },
  ];

  const rowData = [];
  tableList?.forEach((elem, index) => {
    let obj = [
      {
        value: <span>{filterData?.offset + index + 1}</span>,
      },
      {
        value: (
          <span
            className="pointer color-new-car"
            onClick={() => {
              handleRedirect(elem?.id);
            }}
          >
            {elem?.paperId}
          </span>
        ),
      },
      {
        value: (
          <div style={{ width: "200px" }}>
            {titleCaseString(elem?.paperTitle)}
          </div>
        ),
      },
      {
        value: <div className="text-nowrap">{elem?.memberId}</div>,
      },
      {
        value: <div>{elem?.membershipCategory}</div>,
      },
      {
        value: (
          <div className="text-14-500 color-new-car" style={{ width: "150px" }}>
            {elem?.authorName}
          </div>
        ),
      },
      {
        value: <span className="text-14-500">{elem?.authorEmail}</span>,
      },
      {
        value: <div style={{ width: "200px" }}>{elem?.conference}</div>,
      },
      // {
      //   value: <div style={{ width: "170px" }}>{elem?.session}</div>,
      // },
      {
        value: <div style={{ width: "170px" }}>{elem?.university}</div>,
      },
      {
        value: <div style={{ width: "170px" }}>{elem?.country}</div>,
      },
      {
        value: (
          <div style={{ width: "100px" }}>
            {searchPayload?.statusType === "Reviewer"
              ? getOtherStatus(elem?.submitted_status)
              : getStatus(elem?.submitted_status)}
          </div>
        ),
      },
      {
        value: (
          <div className={leadColor(elem?.lead)} style={{ width: "100px" }}>
            {elem?.lead}
          </div>
        ),
      },
      {
        value: <div style={{ width: "200px" }}>{elem?.remark}</div>,
      },
      {
        value: (
          <span className="action-icon-buttons align-items-center">
            <>
              <i
                className="bi bi-eye pointer color-new-car"
                onClick={() => {
                  handleRedirect(elem?.id);
                }}
              />
              <CommentAddView
                icon={<i className="bi bi-chat-right-text position-relative" />}
                elem={elem}
                handelSuccess={() => {
                  getDetailedViewData({ ...filterData, ...searchPayload });
                }}
              />

              <ActionFilter
                parentClass="op-drop-down"
                id="op-drop-down"
                text={
                  <i className="bi bi-three-dots-vertical text-16-400 color-dark-silver" />
                }
                elem={elem}
                handleSuccess={() => {
                  getDetailedViewData({ ...filterData, ...searchPayload });
                }}
                isOpen={abstractId === elem?.id}
                onToggle={() => handleToggle(elem?.id)}
              />
            </>
          </span>
        ),
      },
    ];
    rowData.push({ data: obj });
  });
  return (
    <Card className="p-2 unset-br">
      <div className="d-flex justify-content-between align-items-center cpt-28 cpb-28 flex-wrap gap-2">
        <div className="table-title">Detailed View ({filterData?.total})</div>
        <div className="d-flex align-items-center flex-wrap gap-3">
          {searchPayload?.statusType && (
            <div className="d-flex align-items-center flex-wrap gap-2">
              <span className="text-14-500 color-0000">You Selected:</span>
              <span className="text-14-400 color-new-car b-new-car rounded h-35 d-flex align-items-center ps-2 pe-2 bg-new-car-light">
                {`${searchPayload?.statusType} : ${searchPayload?.statuss}`}
              </span>
            </div>
          )}
          <ExportButton
            exportAPI={exportSubmittedAbstracts}
            payload={objectToFormData({ ...searchPayload })}
          />
          {/* {access?.isFilter && ( */}
          <div className="d-flex">
            <FilterDropdown
              list={membershipList}
              handelChangeFilter={handelChangeFilter}
            />
          </div>
          {/* )} */}
        </div>
      </div>
      <div className="overflow-auto">
        <Table
          isLoading={isLoading}
          header={header}
          rowData={rowData}
          filterData={filterData}
          searchPayload={searchPayload}
          searchInputChange={handelChangeSearch}
          changeOffset={handelChangePagination}
          isOtherPagination
        />
      </div>
    </Card>
  );
};

export default DetailedView;
