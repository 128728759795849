import React from "react";
import FirstStepOfFollowUps from "./RegisterTemplatesForm/FirstStepOfFollowUps";
import SecondStepOfFollowUps from "./RegisterTemplatesForm/SecondStepOfFollowUps";
import ThirdStepOfFollowUps from "./RegisterTemplatesForm/ThirdStepOfFollowUps";
import FourthStepOfFollowUps from "./RegisterTemplatesForm/FourthStepOfFollowUps";
import FifthStepOfFollowUps from "./RegisterTemplatesForm/FifthStepOfFollowUps";
import SixthStepOfFollowUps from "./RegisterTemplatesForm/SixthStepOfFollowUps";
import SevenStepOfFollowUps from "./RegisterTemplatesForm/SevenStepOfFollowUps";
import EigthStepOfFollowUps from "./RegisterTemplatesForm/EigthStepOfFollowUps";

const RegistrationFollowUpsTemplatesForm = ({ props }) => {
  const { values } = props;

  const getFormComponent = (type) => {
    switch (type) {
      case "Session name inclusion notification":
        return <FirstStepOfFollowUps props={props} />;
      case "Coupon or Voucher follow ups/early bird deadline":
        return <SecondStepOfFollowUps props={props} />;
      case "Publication Details Few slots left":
        return <ThirdStepOfFollowUps props={props} />;
      case "Session-Based Publication Opportunities and Conference Outcomes at [Conference Name]":
        return <FourthStepOfFollowUps props={props} />;
      case "Keynote speaker, session speaker notification":
        return <FifthStepOfFollowUps props={props} />;
      case "Agenda Page and Slot Allotment notification":
        return <SixthStepOfFollowUps props={props} />;
      case "Hall Ticket, Zoom link and Confirmation":
        return <SevenStepOfFollowUps props={props} />;
      case "Full Paper Follow-Up Email":
        return <EigthStepOfFollowUps props={props} />;

      default:
        return (
          <div className="center-flex">
            Please select the value from the dropdown
          </div>
        );
    }
  };
  return <div className="row">{getFormComponent(values?.followUps)}</div>;
};

export default RegistrationFollowUpsTemplatesForm;
