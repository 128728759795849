import { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";
import AppRoutes from "routes/appRoutes";
import AuthRoutes from "routes/authRoutes";
import ErrorPopup from "components/Layout/ErrorPopup";
import NoInternet from "components/Layout/NoInternet";
import WebsiteMaintenance from "components/Layout/WebsiteMaintenance/WebsiteMaintenance";
// import { getDataFromLocalStorage } from "utils/helpers";
import { useSelector } from "react-redux";
import posthog from "posthog-js";
import "assets/main.scss";
import { generatePreSignedUrl } from "utils/helpers";
import { profilePath } from "utils/constants";

const ClientID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
// const authorizedUsers = [
//   "hemantadmin@mailinator.com",
//   "priteshadmin@mailinator.com",
//   "rajdeepadmin@mailinator.com",
//   "admin@mailinator.com",
//   "priteshp@mailinator.com",
//   "priteshi@mailinator.com",
//   "priteshs@mailinator.com",
//   "priteshrc@mailinator.com",
//   "hemantpr@mailinator.com",
//   "hemants@mailinator.com",
//   "hemanti@mailinator.com",
//   "hemantcr@mailinator.com",
//   "hemantcr@mailinator.com",
//   "rajdeep@sourcecubeindia.com",
//   "pritesh@sourcecubeindia.com",
//   "hemant@sourcecubeindia.com",
//   "jaya@sourcecubeindia.com",
//   "rajdeep@sourcecubeindia.com",
//   "rajdeepp@mailinator.com",
// ];

const App = () => {
  // eslint-disable-next-line no-unused-vars
  const { userData, state } = useSelector((state) => ({
    userData: state.auth.userData,
  }));
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  const updateOnlineStatus = () => {
    const isOnlineStatus = !!navigator.onLine;
    setIsOnline(isOnlineStatus);
  };
  useEffect(() => {
    // disable dev tool
    // window.addEventListener("keydown", handleKeyDown);
    // window.addEventListener("contextmenu", handleInspect);

    window.addEventListener("offline", updateOnlineStatus);
    window.addEventListener("online", updateOnlineStatus);
  }, []);

  const userIdentity = async (details) => {
    // Send user details to PostHog dynamically
    posthog.identify(details?.id, {
      name: `${details?.first_name} ${details?.last_name}`,
      email: details?.email_id,
      profile_image: generatePreSignedUrl(
        details?.profile_photo_path,
        profilePath
      ),
    });
  };

  useEffect(() => {
    if (localStorage?.userData && userData?.id) {
      userIdentity(userData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage?.userData && userData]);

  const isAuthenticated = localStorage.userData ? true : false;
  const isUnderMaintenance = false;

  // disable dev tool
  // const handleInspect = (e) => {
  //   if (!authorizedUsers.includes(getDataFromLocalStorage("email_id"))) {
  //     e.preventDefault();
  //   }
  // };

  // const handleKeyDown = (e) => {
  //   if (!authorizedUsers.includes(getDataFromLocalStorage("email_id"))) {
  //     if (e.key === "F12") {
  //       e.preventDefault();
  //     }
  //     if (e.ctrlKey && e.shiftKey && e.key === "I") {
  //       e.preventDefault();
  //     }
  //     if (e.ctrlKey && e.shiftKey && e.key === "C") {
  //       e.preventDefault();
  //     }
  //     if (e.ctrlKey && e.shiftKey && e.key === "J") {
  //       e.preventDefault();
  //     }
  //     if (e.ctrlKey && e.key === "u") {
  //       e.preventDefault();
  //     }
  //   }
  // };

  return (
    <div className="App bg-ghost-white">
      <ErrorPopup />
      {isUnderMaintenance ? (
        <WebsiteMaintenance />
      ) : isOnline ? (
        isAuthenticated ? (
          <BrowserRouter>
            <AppRoutes />
          </BrowserRouter>
        ) : (
          <GoogleOAuthProvider clientId={ClientID}>
            <AuthRoutes />
          </GoogleOAuthProvider>
        )
      ) : (
        <NoInternet />
      )}
    </div>
  );
};

export default App;
