import React from "react";
// Agenda Page and Slot Allotment notification
const SixthStepTemplate = ({ values }) => {
  const {
    client_name,
    conference_name,
    conference_date,
    conference_location,
    industry,
    your_date_time,
    your_session_title,
    session_venue,
    view_full_agenda,
    contact_support,
    your_name,
    your_title,
    your_contact_information,
    your_website,
  } = values;
  return (
    <div>
      <p>
        {client_name
          ? `${client_name},`
          : ` Dear [Client Name] / Dr. [Client Name],`}
      </p>

      <p>Greetings from IFERP!</p>

      <p>
        We are excited to share the comprehensive agenda and your slot allotment
        for{" "}
        <strong>
          {conference_name ? conference_name : "[Conference Name]"}
        </strong>
        , which will take place from{" "}
        <strong>
          {conference_date ? conference_date : "[Conference Dates]"}
        </strong>{" "}
        at{" "}
        <strong>
          {conference_location ? conference_location : "[Conference Venue]"}
        </strong>
        .
      </p>

      <h6>
        <strong>Comprehensive Agenda:</strong>
      </h6>

      <p>
        Our conference agenda is designed to provide you with a diverse and
        enriching experience. Here's a glimpse of what you can expect:
      </p>

      <ul>
        <li>
          <strong>Keynote Sessions:</strong> Engage with renowned experts and
          gain insights into the latest trends and advancements in
          <strong>{industry ? industry : "[Field/Industry]"}</strong>.
        </li>
        <li>
          <strong>Workshops:</strong> Participate in hands-on sessions and
          acquire practical knowledge and skills.
        </li>
        <li>
          <strong>Networking Events:</strong> Connect with peers, researchers,
          and professionals to foster collaboration and exchange ideas.
        </li>
        <li>
          <strong>Q&A Sessions:</strong> Take advantage of dedicated time slots
          to ask questions and delve deeper into the topics presented.
        </li>
      </ul>

      <h6>
        <strong>Your Slot Allotment:</strong>
      </h6>

      <p>
        You have been allotted the following slot for your presentation at the
        conference:
      </p>
      <p>
        <strong>Date and Time:</strong>{" "}
        {your_date_time ? your_date_time : "[Your Slot Date and Time]"}
      </p>
      <p>
        <strong>Session Title:</strong>{" "}
        {your_session_title ? your_session_title : "[Your Session Title]"}
      </p>
      <p>
        <strong>Venue:</strong>{" "}
        {session_venue ? session_venue : "[Session Venue]"}
      </p>

      <p>
        For a detailed view of the full agenda and your presentation details,
        please visit the link below:
      </p>

      <p>
        {view_full_agenda ? (
          <a href={view_full_agenda}>View Full Agenda</a>
        ) : (
          <a href="[View Full Agenda]">View Full Agenda</a>
        )}
      </p>

      <h6>
        <strong>Prepare for the Conference:</strong>
      </h6>

      <p>
        Make the most of your conference experience by preparing in advance.
        Should you need further details or assistance, feel free to reach out to
        us.
      </p>

      <p>
        {contact_support ? (
          <a href={contact_support}>Contact Support</a>
        ) : (
          <a href="[Contact Support]">Contact Support</a>
        )}
      </p>

      <p>
        We look forward to your participation and contribution to{" "}
        <strong>
          {conference_name ? conference_name : "[Conference Name]"}
        </strong>
        . Your presence will enrich the event, and we are excited about the
        insights you'll bring to the discussions.
      </p>

      <div>Best regards,</div>
      <div>{your_name ? your_name : "[Your Name]"}</div>
      <div>{your_title ? your_title : "[Your Title]"}</div>
      <div>
        <strong>IFERP</strong>
      </div>
      <div>
        {your_contact_information
          ? your_contact_information
          : "[Your Contact Information]"}
      </div>
      <div>
        {your_website ? (
          <a href={your_website}>{your_website}</a>
        ) : (
          "[Your Website]"
        )}
      </div>
    </div>
  );
};

export default SixthStepTemplate;
