export const templateInitialValues = {
  "Session name inclusion notification": {
    client_name: "",
    conference_name: "",
    conference_organizer: "",
    conference_date: "",
    conference_location: "",
    session_title: "",
    brief_overview: "",
    paper_title: "",
    relevant_field: "",
    view_session_details: "",
    support_email: "",
    your_organization_name: "",
    your_contact_information: "",
    your_website: "",
  },
  "Coupon or Voucher follow ups/early bird deadline": {
    client_name: "",
    conference_name: "",
    conference_date: "",
    conference_location: "",
    coupon_code: "",
    discount_amount: "",
    expiration_date: "",
    register_now: "",
    support_email: "",
    your_name: "",
    your_title: "",
    your_contact_information: "",
    your_website: "",
  },
  "Publication Details Few slots left": {
    client_name: "",
    conference_name: "",
    conference_date: "",
    conference_location: "",
    registration_link: "",
    your_name: "",
    your_title: "",
    your_contact_information: "",
    your_website: "",
  },
  "Session-Based Publication Opportunities and Conference Outcomes at [Conference Name]":
    {
      client_name: "",
      conference_name: "",
      conference_date: "",
      conference_location: "",
      paper_title: "",
      registration_link: "",
      your_name: "",
      your_title: "",
      your_contact_information: "",
      your_website: "",
    },
  "Keynote speaker, session speaker notification": {
    client_name: "",
    conference_name: "",
    conference_date: "",
    conference_location: "",
    featured_speakers: [
      {
        speaker_name: "",
        speaker_credentials: "",
        speaker_achievements: "",
        speaker_sessionTopic: "",
      },
    ],
    pre_conference_speaker_name: "",
    date_time: "",
    register_webinar: "",
    view_speaker_profile: "",
    register_conference: "",
    your_name: "",
    your_title: "",
    your_contact_information: "",
    your_website: "",
  },
  "Agenda Page and Slot Allotment notification": {
    client_name: "",
    conference_name: "",
    conference_date: "",
    conference_location: "",
    industry: "",
    your_date_time: "",
    your_session_title: "",
    session_venue: "",
    view_full_agenda: "",
    contact_support: "",
    your_name: "",
    your_title: "",
    your_contact_information: "",
    your_website: "",
  },
  "Hall Ticket, Zoom link and Confirmation": {
    client_name: "",
    conference_name: "",
    venue_name: "",
    street_address: "",
    city: "",
    state: "",
    postal_code: "",
    country: "",
    map_link: "",
    zoom_link: "",
    download_zoom: "",
    registration_venue_name: "",
    registration_street_address: "",
    registration_date_time: "",
    hall_ticket_link: "",
    support_email: "",
    support_phone: "",
    your_name: "",
    your_title: "",
    your_contact_information: "",
    your_website: "",
  },
  "Full Paper Follow-Up Email": {
    client_name: "",
    conference_name: "",
    conference_date: "",
    conference_location: "",
    submission_date_line: "",
    guideline: "",
    submission_full_paper: "",
    contact_support: "",
    your_name: "",
    your_title: "",
    your_contact_information: "",
    your_website: "",
  },
};
