import React from "react";
// Session-Based Publication Opportunities and Conference Outcomes at {conference_name?conference_name:"[Conference Name]"}
const FourthStepTemplate = ({ values }) => {
  const {
    client_name,
    paper_title,
    conference_name,
    conference_date,
    conference_location,
    registration_link,
    your_name,
    your_title,
    your_contact_information,
    your_website,
  } = values;
  return (
    <div>
      <p>
        {client_name
          ? `${client_name},`
          : ` Dear [Client Name] / Dr. [Client Name],`}
      </p>

      <p>Greetings from IFERP!</p>

      <p>
        We are delighted to inform you that your article titled "
        <strong>{paper_title ? paper_title : "[Paper Title]"}</strong>" has been
        accepted for presentation at{" "}
        <strong>
          {conference_name ? conference_name : "[Conference Name]"}
        </strong>
        , scheduled to take place on{" "}
        <strong>{conference_date ? conference_date : "[Date]"}</strong> at{" "}
        <strong>{conference_location ? conference_location : "[Venue]"}</strong>
        .
      </p>

      <h6>
        <strong>Conference Highlights:</strong>
      </h6>
      <ul>
        <li>
          <strong>Outcome:</strong> Engage in vibrant discussions and networking
          opportunities that drive impactful research and innovation.
        </li>
        <li>
          <strong>Publication Opportunities:</strong> Selected papers will be
          submitted for evaluation and indexing in Scopus and Web of Science
          (WoS) (T&C apply).
        </li>
        <li>
          <strong>High-Impact Journals:</strong> Depending on session relevance,
          you'll also have the opportunity to publish in high-impact journals.
        </li>
        <li>
          <strong>Successful Publication Track Record:</strong> Many past
          participants have successfully published in prestigious journals,
          gaining visibility and recognition.
        </li>
        <li>
          <strong>SDG Alignment:</strong> Sessions are aligned with the United
          Nations’ Sustainable Development Goals (SDGs), contributing to global
          progress through research.
        </li>
        <li>
          <strong>Certifications:</strong> All participants will receive
          official certificates recognizing their contributions and
          participation.
        </li>
      </ul>

      <h6>
        <strong>Indexing and Publication:</strong>
      </h6>
      <p>
        The proceedings of{" "}
        <strong>
          {conference_name ? conference_name : "[Conference Name]"}
        </strong>{" "}
        will be submitted to the{" "}
        <strong>Web of Science Book Citation Index (BkCI) and Scopus </strong>
        for evaluation and indexing (T&C apply), ensuring enhanced visibility
        and impact for your research within the academic community.
      </p>

      <h6>
        <strong>Next Steps:</strong>
      </h6>
      <p>
        To confirm your participation, please complete your registration by
        following the link below:
      </p>

      <p>
        <strong>
          {registration_link ? registration_link : "[Register Here]"}
        </strong>
      </p>

      <p>
        We are confident that your presentation will add significant value to
        the conference, and we look forward to the insights and contributions
        you will bring to our global community.
      </p>

      <p>
        Should you have any questions or require assistance, feel free to
        contact us.
      </p>

      <div>Best regards,</div>
      <div>{your_name ? your_name : "[Your Name]"}</div>
      <div>{your_title ? your_title : "[Your Title]"}</div>
      <div>
        <strong>IFERP</strong>
      </div>
      <div>
        {your_contact_information
          ? your_contact_information
          : "[Your Contact Information]"}
      </div>
      <div>
        {your_website ? (
          <a href={your_website}>{your_website}</a>
        ) : (
          "[Your Website]"
        )}
      </div>
    </div>
  );
};

export default FourthStepTemplate;
