import React from "react";
// "Publication Details Few slots left"
const ThirdStepTemplate = ({ values }) => {
  const {
    client_name,
    conference_name,
    conference_date,
    conference_location,
    registration_link,
    your_name,
    your_title,
    your_contact_information,
    your_website,
  } = values;
  return (
    <div>
      <p>
        {client_name
          ? `${client_name},`
          : ` Dear [Client Name] / Dr. [Client Name],`}
      </p>

      <p>Greetings from IFERP!</p>

      <p>
        We are pleased to announce that ISBN and ISSN numbers are now available
        for the upcoming{" "}
        <strong>
          {conference_name ? conference_name : "[Conference Name]"}
        </strong>
        , scheduled to take place from{" "}
        <strong>
          {conference_date ? conference_date : "[Conference Date]"}
        </strong>{" "}
        at{" "}
        <strong>
          {conference_location ? conference_location : "[Conference Venue]"}
        </strong>
        .
      </p>

      <h6>
        <strong>Why This Matters:</strong>
      </h6>
      <p>
        Obtaining an ISBN or ISSN number not only validates the authenticity of
        your work but also enhances its visibility and accessibility within the
        global academic and professional community. This prestigious
        accreditation ensures that your research is recognized and respected on
        a broader scale.
      </p>

      <h6>
        <strong>Publication Opportunities:</strong>
      </h6>
      <p>
        By participating in{" "}
        <strong>
          {conference_name ? conference_name : "[Conference Name]"}
        </strong>
        , you will have the opportunity to publish your research in high-impact
        journals indexed in Scopus and Web of Science (WoS). This can
        significantly enhance the credibility of your work and extend your
        academic reach, giving you greater visibility in the research community.
      </p>

      <h6>
        <strong>Act Now – Limited Slots Available:</strong>
      </h6>
      <p>
        Due to overwhelming interest, slots for securing an ISBN and ISSN number
        are limited. We encourage you to register early to guarantee your place
        and take advantage of this prestigious opportunity for your research.
      </p>

      <h6>
        <strong>Register Here:</strong>
      </h6>
      <p>{registration_link ? registration_link : "[Registration Link]"}</p>

      <p>
        We are looking forward to your active participation and contribution to
        the success of this conference. Should you have any questions or need
        further assistance, please feel free to reach out to us.
      </p>

      <div>Best regards,</div>
      <div>{your_name ? your_name : "[Your Name]"}</div>

      <div>{your_title ? your_title : "[Your Title]"}</div>
      <div>
        <strong>IFERP</strong>
      </div>
      <div>
        {your_contact_information
          ? your_contact_information
          : "[Your Contact Information]"}
      </div>

      <div>
        {your_website ? (
          <a href={your_website}>{your_website}</a>
        ) : (
          "[Your Website]"
        )}
      </div>
    </div>
  );
};

export default ThirdStepTemplate;
