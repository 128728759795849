import React, { useState } from "react";
import RadioInput from "components/form/RadioInput";
import SingleForm from "./SingleForm";
import "./RegistrationPayment.scss";

const RegistrationPayment = ({ paperData, handleSuccess }) => {
  const [paymentType, setPaymentType] = useState("0");
  return (
    <>
      <div className="d-flex align-items-center gap-5 cmb-30">
        <div className="text-14-400-18 color-1717">Payment Type:</div>
        <div className="d-flex align-items-center gap-3">
          <div className="d-flex align-items-center ms-5">
            <RadioInput
              name="payment_type"
              id="payment_type"
              onChange={() => {
                setPaymentType("0");
              }}
              checked={paymentType === "0"}
            />

            <div className="text-16-400 color-raisin-black ms-3">Single</div>
          </div>

          <div className="d-flex align-items-center ms-5">
            <RadioInput
              name="payment_type"
              id="payment_type"
              onChange={() => {
                setPaymentType("1");
              }}
              checked={paymentType === "1"}
            />

            <div className="text-16-400 color-raisin-black ms-3">Bulk</div>
          </div>
        </div>
      </div>

      {paymentType === "0" && (
        <SingleForm
          type={paymentType}
          paperData={paperData}
          handleSuccess={handleSuccess}
        />
      )}
      {paymentType === "1" && (
        <SingleForm
          type={paymentType}
          paperData={paperData}
          handleSuccess={handleSuccess}
        />
      )}
    </>
  );
};

export default RegistrationPayment;
